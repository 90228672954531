import React from 'react'
import { Card, Text, Group,Spoiler,Tabs} from '@mantine/core';
import {db} from "../../config/firebase-config"
import {  doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import ReactMarkdown from 'react-markdown'

import "./decisionPage.css"


const SummaryDecisonsCard = (props) =>{


  const { userId } = props;
  const [articleData, setArticleData] = useState(null); // État pour stocker les données de l'article
  const [textAnalysis,setTextAnalysis] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "law_text", userId);
        const user = await getDoc(docRef);
        if (user.exists()) {
          setArticleData(user.data());
          
          if (articleData){
            const {text_summary} = articleData


            setTextAnalysis(text_summary[0])
          }
         
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du document :", error);
      }
    };

    fetchData(); // Appelez la fonction fetchData dans useEffect pour récupérer les données au chargement du composant.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[articleData]); 



  return (
      <>
      {textAnalysis ? (
        <div >
       
      
      <br></br>
       
          <br></br>
          <div style={{ textAlign: 'left',"marginLeft":"5%","width": "90%" }}>
          <ReactMarkdown >{textAnalysis}</ReactMarkdown>

          </div>
         
        </div>
      ) : (

        
        <Group  mt="md" mb="xs" justify="center">
      <Loader color="blue" size="xl" type="dots" />
      </Group>
     
      )}
      
      </>
    
  );
}

  

  /*
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder my="2em" style={{"position":"sticky","width":"700px"}} >
      <Card.Section withBorder inheritPadding py="xs">

      <Group justify="space-between">
        <div></div>
      <Badge color="blue" variant="light" style={{"position":"revert"}}>
               {juridiction}
              </Badge>
        </Group>
            
        <Group justify="space-between">
          <Text fw={500}>{Titre}</Text>
          </Group>

      </Card.Section>

      
    <Spoiler maxHeight={80} showLabel="Voir plus" hideLabel="Hide">
    <Text> Contexte : 
    <Text size="sm" c="dimmed">{Contexte} </Text></Text>
    </Spoiler>
    <hr/>
    <Text size="xs">Domaines de droit : {domaine}</Text>
    <Text size="xs">Mot clés : {MotsCles}</Text>
    </Card>
  )
}*/

 
const DecisionCard =  (props) => {
  const { userId } = props;
  const [articleData, setArticleData] = useState(null); // État pour stocker les données de l'article
  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "law_text", userId);
        const user = await getDoc(docRef);
        if (user.exists()) {
          setArticleData(user.data());
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du document :", error);
      }
    };

    fetchData(); // Appelez la fonction fetchData dans useEffect pour récupérer les données au chargement du composant.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  return (
      <>
      {articleData ? (
        <div >
       
      
      <br></br>
        <Card shadow="sm" padding="lg" radius="md" withBorder my="2em" style={{"marginLeft":"5%"}}>
        <Group justify="space-between" mt="md" mb="xs">

           <Text fw={500}>{articleData.keyword}</Text>
          </Group>
          <Group  mt="md" mb="xs">
            <Text fw={500}><div dangerouslySetInnerHTML={{__html:articleData.corpus}} style={{"textAlign":"left"}} /> </Text>
          </Group>
          <Spoiler maxHeight={80} showLabel="Voir plus" hideLabel="Hide">
            <Text size="sm" c="dimmed">
              {/* Utilisez articleData pour afficher les données de l'article */}
            </Text>
          </Spoiler>
          </Card>
        </div>
      ) : (

        <Card shadow="sm" padding="lg" radius="md" withBorder my="2em" style={{"width": "50%"}}>
        <p>Chargement en cours...</p>
        </Card>
      )}
      </>
    
  );
}








const DecisionContentPage = (props) => {
  let orient =null;
  if (window.innerWidth <= 1000){
    orient= "horizontal"
  }else {
    orient = "vertical"
  }
  return (

        <div>

    
      <Tabs defaultValue="analysis" orientation={orient} >
      <Tabs.List >
      <Tabs.Tab value="summary">Résumé de la décision</Tabs.Tab>
      <Tabs.Tab value="analysis">Analyse de la décisions</Tabs.Tab>
       <Tabs.Tab value="decision">Décision </Tabs.Tab>
       <Tabs.Tab value="decisionsimilar">Décisions Similaires </Tabs.Tab>
        <Tabs.Tab value="comment">Commentaires</Tabs.Tab>
        <Tabs.Tab value="law">Articles cités</Tabs.Tab>

      </Tabs.List>
      <Tabs.Panel value="summary">résume</Tabs.Panel>
      <Tabs.Panel value="analysis"> <SummaryDecisonsCard {...props}></SummaryDecisonsCard> </Tabs.Panel>
      <Tabs.Panel value="decision"> <DecisionCard {...props}></DecisionCard></Tabs.Panel>
      <Tabs.Panel value="decisionsimilar">Contenu Décisions similaires</Tabs.Panel>
      <Tabs.Panel value="comment">Contenu Commentaires</Tabs.Panel>
      <Tabs.Panel value="law">Contenu Articles cités</Tabs.Panel>

    </Tabs>
    </div>
    

  );
}

export default  DecisionContentPage