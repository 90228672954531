import React from 'react';
import { Card, Text, Badge, Group, Spoiler, UnstyledButton, Container, ActionIcon, rem, useMantineTheme } from '@mantine/core';
import { useNavigate } from "react-router-dom";
import { IconBookmark, IconGavel, IconBook } from '@tabler/icons-react';
import styled from 'styled-components';
import { theme } from '../themes'; // Adjust the path as needed

const StyledText = styled.span`
  font-size: ${props => props.size};
  font-weight: ${props => props.fw};
  letter-spacing: ${props => props.psan};
  color: ${theme.colors.text};
  font-family: ${theme.fonts.body}; // Apply Georgia font
  
  &:hover {
    text-decoration: underline;
  }
`;

const DecisonsCard = (props) => {
  const navigate = useNavigate();
  const mantineTheme = useMantineTheme();

  const navigateToArticle = (type, userId) => {
    if (type === "Lois et Réglements") {
      navigate(`/article/${userId}`);
    } else if (type === "Décisions") {
      navigate(`/decision/${userId}`);
    }
  };

  const { juridiction = 'Unknown', category_text, id, text_summary = 'No summary available', titre = 'No title', keyword_decision = [], domaine_decision = [], theme_decision = [] } = props;

  const categoryIcon = category_text === "Décisions" ? <IconGavel size={20} color={theme.colors.blue.main} /> : <IconBook size={20} color={theme.colors.blue.dark} />;

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder my="2em" style={{ "position": "sticky", backgroundColor: theme.colors.background }}>
      <Card.Section withBorder inheritPadding py="xs" style={{ backgroundColor: theme.colors.cloud }}>
        <Group justify="space-between">
          <Badge color={theme.colors.primary} variant="light" radius="sm" style={{ color: theme.colors.text }}>
            {juridiction}
          </Badge>
          {categoryIcon}
        </Group>
        <br />
        <Group justify="space-between">
          <UnstyledButton onClick={() => navigateToArticle(category_text, id)}
            styles={{
              root: {
                '&:not([dataDisabled]):hover': { backgroundColor: theme.colors.blue.light },
              },
            }}
          >
            <StyledText size="sm" fw={500} psan> {titre} </StyledText>
          </UnstyledButton>
        </Group>
      </Card.Section>

      <div style={{ textAlign: 'left', padding: '0 16px', color: theme.colors.text, fontFamily: theme.fonts.body }}>
        <Spoiler maxHeight={110} showLabel="Voir plus" hideLabel="Voir moins">
          <Text style={{ textAlign: 'left', fontFamily: theme.fonts.body }} dangerouslySetInnerHTML={{ __html: text_summary }} />
        </Spoiler>
      </div>

      <hr />
      <Text size="xs" ta={"left"} c="dimmed" mt="sm" style={{ color: theme.colors.secondary, fontFamily: theme.fonts.body }}>
        Domaines de droit :
        {domaine_decision.length > 0 ? domaine_decision.map((domaine, index) => (
          <Badge key={index} color={theme.colors.accent} variant="light" radius="sm" style={{ marginLeft: '0.2rem', color: theme.colors.text }}>
            {domaine}
          </Badge>
        )) : 'Aucun domaine'}
      </Text>
      <Text size="xs" ta={"left"} c="dimmed" mt="sm" style={{ color: theme.colors.secondary, fontFamily: theme.fonts.body }}>
        Thèmes :
        {theme_decision.length > 0 ? theme_decision.map((themeItem, index) => (
          <Badge key={index} color={theme.colors.accent} variant="light" radius="sm" style={{ marginLeft: '0.2rem', color: theme.colors.text }}>
            {themeItem}
          </Badge>
        )) : 'Aucun thème'}
      </Text>
      <Text size="xs" ta={"left"} c="dimmed" mt="sm" mb="md" style={{ color: theme.colors.secondary, fontFamily: theme.fonts.body }}>
        Mot clés :
        {keyword_decision.length > 0 ? keyword_decision.map((keyword, index) => (
          <Badge key={index} color={theme.colors.accent} variant="light" radius="sm" style={{ marginLeft: '0.2rem', color: theme.colors.text }}>
            {keyword}
          </Badge>
        )) : 'Aucun mot clé'}
      </Text>
      <ActionIcon variant="subtle" color="gray" pos={"right"}>
        <IconBookmark
          style={{ width: rem(20), height: rem(20) }}
          color={mantineTheme.colors.yellow[6]}
          stroke={1.5}
        />
      </ActionIcon>
    </Card>
  );
}

export function ArticleHit(props) {
  const { hit } = props;
  const mantineTheme = useMantineTheme();
  const navigate = useNavigate();

  const navigateToArticle = (type, userId) => {
    if (type === "Lois et Réglements") {
      navigate(`/article/${userId}`);
    } else if (type === "Décisions") {
      navigate(`/decision/${userId}`);
    }
  };

  const categoryIcon = hit.category_text === "Décisions" ? <IconGavel size={20} color={theme.colors.blue.main} /> : <IconBook size={20} color={theme.colors.blue.dark} />;

  let category = hit.category_text === "Lois et Réglements" ? hit.law_text : hit.juridiction;

  return (
    <Container>
      {hit.category_text === "Lois et Réglements" ? (
        <Card shadow="sm" padding="lg" radius="md" withBorder my="2em" style={{ "position": "sticky", "width": "700px", backgroundColor: theme.colors.background }}>
          <Card.Section withBorder inheritPadding py="xs" style={{ backgroundColor: theme.colors.cloud }}>
            <Group justify='space-between' mt="md" mb="xs">
              <UnstyledButton onClick={() => navigateToArticle(hit.category_text, hit.id_collection)}
                styles={{
                  root: {
                    '&:not([dataDisabled]):hover': { backgroundColor: theme.colors.blue.light },
                  },
                }}
              >
                <StyledText fw={500}> {category} </StyledText>
              </UnstyledButton>
              {categoryIcon}
            </Group>
          </Card.Section>
          <Group justify="space-between" mt="md" mb="xs"></Group>
          <Spoiler maxHeight={80} showLabel="Voir plus" hideLabel="Voir moins">
            <Text style={{ padding: '0 16px', textAlign: 'left', color: theme.colors.text, fontFamily: theme.fonts.body }} dangerouslySetInnerHTML={{ __html: hit.corpus }} />
          </Spoiler>

          <Group justify="space-between" mt="md" mb="xs">
            <div></div>
            <Badge color={theme.colors.primary} variant="light" style={{ "position": "revert", color: theme.colors.text }}>
              Pas à jour
            </Badge>
          </Group>
          <ActionIcon variant="subtle" color="gray">
            <IconBookmark
              style={{ width: rem(20), height: rem(20) }}
              color={mantineTheme.colors.yellow[6]}
              stroke={1.5}
            />
          </ActionIcon>
        </Card>
      ) : (
        <DecisonsCard {...hit} />
      )}
    </Container>
  );
}
