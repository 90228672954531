import MyNavbar from '../../component/navbar/Navbar';
import { useDisclosure } from '@mantine/hooks';
import { AppShell,Burger,Group } from '@mantine/core';
import {TableOfContentsFloating} from '../../component/search/TableContent'
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";

import {
  InstantSearch,

  
} from "react-instantsearch";

import { Loader } from '@mantine/core';
import React, { useState, useEffect } from 'react';
import "instantsearch.css/themes/satellite.css";
import {NavbarNested} from "../../component/verticalNavbar/NavbarNested"


export const  {searchClient} = instantMeiliSearch(
  'http://34.66.153.123/',
  'MobileDevAppTemp1104',
  {
    primaryKey: 'id',
    keepZeroFacets : true,
      offset : 4,
    limit: 1000,
  totalPages: 4,
  totalHits: 120
  }
);
console.log(searchClient.search); // Should log the search method if configured correctly

export const Search = () => {



  const [opened, { toggle }] = useDisclosure();
  const [healthStatus, setHealthStatus] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('http://34.66.153.123/health');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setHealthStatus(data);
          if (data) {
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const intervalId = setInterval(fetchData, 1000);

      return () => clearInterval(intervalId);
    }, []);

  return (

    <InstantSearch indexName="law_text" searchClient={searchClient} >
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: {  mobile: !opened }
    }}
      padding="md"
    >
      <AppShell.Header>
      <Group h="100%" w="100%">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
      <MyNavbar />
      </Group>
      
      </AppShell.Header>
      <AppShell.Navbar p="md" style={{"backgroundColor":"#F5F5F5"}} >
      <NavbarNested />
      </AppShell.Navbar>
      

      <AppShell.Main>
      {healthStatus ? (<TableOfContentsFloating/>): (<Loader color="blue" size="xl" style={{"justify":"center","marginTop":"20%"}} />)}
      <br/>
      <br/>
         

      
      </AppShell.Main>
    </AppShell>
    </InstantSearch>

  );
  }
export default Search;
