import React from 'react';
import { SearchBox } from 'react-instantsearch';
import "instantsearch.css/themes/satellite.css";


export function Search(props) {
  return (
      <SearchBox
        translations={{
          placeholder: 'Rechercher une décision ou article par mot-clés, date, juridiction...',
        }}
      />
  );
}

export default Search;
