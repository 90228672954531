import { NavLink } from '@mantine/core';
import { IconHome2, IconBooks, IconMessageChatbot, IconGavel, IconFileCertificate, IconBook, IconArticle, IconCertificate, IconSearch, IconDoorEnter } from '@tabler/icons-react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';

const ScrollableNavbar = styled.div`
  max-height: 100vh; // Allow the navbar to take the full height of the viewport
  overflow-y: auto; // Make it scrollable vertically
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FixedBottomNavLink = styled(NavLink)`
  position: fixed;
  bottom: 5%; // Always 5% from the bottom of the window
  width: calc(100% - 20px); // Make sure it fits within the navbar with some padding
  left: 50%;
  transform: translateX(-50%);
`;

export function NavbarNested() {
  const navigate = useNavigate();
  const [isOpenLaw, setIsOpenLaw] = useState(null);
  const [isOpenDecision, setIsOpenDecision] = useState(null);
  const [isOpenNat, setIsOpenNat] = useState(null);
  const [isOpenReg, setIsOpenReg] = useState(null);
  const [isOpenInter, setIsOpenInter] = useState(null);
  const [isOpenConventions, setIsOpenConventions] = useState(null);
  const [isOpenDoctrines, setIsOpenDoctrines] = useState(null);

  const handleNavLinkClickparent = (index) => {
    if (index === 1 && !isOpenLaw) {
      setIsOpenLaw(true);
      setIsOpenDecision(false);
      setIsOpenConventions(false);
      setIsOpenDoctrines(false);
    } else if (index === 2 && !isOpenDecision) {
      setIsOpenLaw(false);
      setIsOpenDecision(true);
      setIsOpenConventions(false);
      setIsOpenDoctrines(false);
    } else if (index === 3 && !isOpenConventions) {
      setIsOpenLaw(false);
      setIsOpenDecision(false);
      setIsOpenConventions(true);
      setIsOpenDoctrines(false);
    } else if (index === 4 && !isOpenDoctrines) {
      setIsOpenLaw(false);
      setIsOpenDecision(false);
      setIsOpenConventions(false);
      setIsOpenDoctrines(true);
    } else {
      setIsOpenLaw(false);
      setIsOpenDecision(false);
      setIsOpenConventions(false);
      setIsOpenDoctrines(false);
    }
  };

  const handleNavLinkClick = (index) => {
    if (index === 1 && !isOpenNat) {
      setIsOpenNat(true);
      setIsOpenReg(false);
      setIsOpenInter(false);
    } else if (index === 2 && !isOpenReg) {
      setIsOpenNat(false);
      setIsOpenReg(true);
      setIsOpenInter(false);
    } else if (index === 3 && !isOpenInter) {
      setIsOpenNat(false);
      setIsOpenReg(false);
      setIsOpenInter(true);
    } else {
      setIsOpenNat(false);
      setIsOpenReg(false);
      setIsOpenInter(false);
    }
  };

  return (
    <ScrollableNavbar>
      <NavLink label="Accueil" leftSection={<IconHome2 size="1.5rem" stroke={1.5} />} 
        href="/home"
      />
      <NavLink 
        label="Recherche" 
        leftSection={<IconSearch size="1.5rem" stroke={1.5} />} 
        href="/search"
      />
      <NavLink 
        label="Assistant juridique" 
        leftSection={<IconMessageChatbot size="1.5rem" stroke={1.5} />} 
        href="/chatbot"
      />

      <NavLink
        label="Lois et Réglements"
        leftSection={<IconBooks size="1.5rem" stroke={1.5} />}
        childrenOffset={28}
        onChange={() => handleNavLinkClickparent(1)} 
        opened={isOpenLaw}
      >
        <NavLink label="Codes Juridiques" onClick={() => navigate("/law/code")} />
        <NavLink label="Réglementations Nationales" onClick={() => navigate("/law/reglementations")} />
        <NavLink label="Traités" onClick={() => navigate("/law/traites")} />
        <NavLink label="Actes Uniformes" onClick={() => navigate("/law/actes-uniformes")} />
        <NavLink label="Accords Internationaux" onClick={() => navigate("/law/accords-internationaux")} />
        <NavLink label="Actualités" onClick={() => navigate("/law/law-news")} />
      </NavLink>

      <NavLink
        label="Décisions"
        leftSection={<IconGavel size="1.5rem" stroke={1.5} />}
        childrenOffset={28}
        onChange={() => handleNavLinkClickparent(2)} 
        opened={isOpenDecision}
      >
        <NavLink label="Nationales" onChange={() => handleNavLinkClick(1)} opened={isOpenNat}>
          <NavLink label="Tribunaux de Grandes Instances" onClick={() => navigate("/decisions/grandes-instances")} />
          <NavLink label="Cours d'Appels" onClick={() => navigate("/decisions/cours-appels")} />
          <NavLink label="Cour Suprême" onClick={() => navigate("/decisions/cours-supreme")} />
          <NavLink label="Tribunaux Spécialisés" onClick={() => navigate("/decisions/tribunaux-specialises")} />
        </NavLink>

        <NavLink label="Régionales" onChange={() => handleNavLinkClick(2)} opened={isOpenReg}>
          <NavLink label="Cour de justice de la CEDEAO" onClick={() => navigate("/decisions/cedeao")} />
          <NavLink label="Cour de justice de l'UEMOA" onClick={() => navigate("/decisions/uemoa")} />
          <NavLink label="Cour Commune de Justice et d'Arbitrage (OHADA)" onClick={() => navigate("/decisions/ohada")} />
        </NavLink>

        <NavLink label="Internationales" onChange={() => handleNavLinkClick(3)} opened={isOpenInter}>
          <NavLink label="Cour Internationale de Justice (CIJ)" onClick={() => navigate("/decisions/cij")} />
          <NavLink label="Cour Pénale Internationale (CPI)" onClick={() => navigate("/decisions/cpi")} />
          <NavLink label="Autres Organisations Internationales" onClick={() => navigate("/decisions/autres")} />
        </NavLink>

        <NavLink label="Actualités" onClick={() => navigate("/decisions/decisons-news")} />
      </NavLink>

      <NavLink
        label="Conventions"
        leftSection={<IconFileCertificate size="1.5rem" stroke={1.5} />}
        childrenOffset={28}
        onChange={() => handleNavLinkClickparent(3)} 
        opened={isOpenConventions}
      >
        <NavLink label="Conventions Collectives" onClick={() => navigate("/conventions/collectives")} />
        <NavLink label="Conventions Fiscales" onClick={() => navigate("/conventions/fiscales")} />
      </NavLink>

      <NavLink
        label="Doctrines"
        leftSection={<IconBook size="1.5rem" stroke={1.5} />}
        childrenOffset={28}
        onChange={() => handleNavLinkClickparent(4)} 
        opened={isOpenDoctrines}
      >
        <NavLink label="Ouvrages" onClick={() => navigate("/doctrines/ouvrages")} />
        <NavLink label="Articles" onClick={() => navigate("/doctrines/articles")} />
        <NavLink label="Mélanges" onClick={() => navigate("/doctrines/melanges")} />
      </NavLink>

      <NavLink
        label="Thèses"
        leftSection={<IconCertificate size="1.5rem" stroke={1.5} />}
        onClick={() => navigate("/theses")}
      />

      <NavLink
        label="Mémoires"
        leftSection={<IconArticle size="1.5rem" stroke={1.5} />}
        onClick={() => navigate("/memoires")}
      />

      {/* "Retour au portail" NavLink fixed at the bottom */}
      <FixedBottomNavLink
        label="Retour au portail"
        leftSection={<IconDoorEnter size="1.5rem" stroke={1.5} />}
        onClick={() => navigate('/portail')}
      />
    </ScrollableNavbar>
  );
}
