import React, { useState, useEffect } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { Chip, Button, HoverCard, Badge, Input } from '@mantine/core';
import "./refinementList.module.css"

const CustomRefinementList = ({ attribute, label, searchable,limit }) => {
  const { items, refine, searchForItems } = useRefinementList({ attribute ,limit});

  const [totalResults, setTotalResults] = useState(0);

  useEffect(() => {
    const sumOfResults = items.reduce((sum, item) => sum + item.count, 0);
    setTotalResults(sumOfResults);
  }, [items]);

  return (
    <HoverCard shadow="md" withArrow position="bottom" arrowSize={10} withinPortal offset={20}>
      <HoverCard.Target>
        <Button variant="light" size="xs" style={{ margin: '4px', marginBottom: '.05rem' }}>
          {label} | <Badge color="gray" variant="light" radius="sm">{totalResults}</Badge>
        </Button>
      </HoverCard.Target>
      <HoverCard.Dropdown style={{ maxHeight: '20rem', overflowY: 'scroll' }}>
        {searchable && (
          <div>
            <Input
              type="search"
              placeholder={`Rechercher par ${label}`}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck={false}
              maxLength={512}
              onChange={(event) => searchForItems(event.currentTarget.value)}
            />
          </div>
        )}
        <hr />
        {items.map(item => (
          <div key={item.label} style={{ marginBottom: '.5rem' }}>
            <Chip
              size="xs"
              variant="light"
              checked={item.isRefined}
              onChange={() => refine(item.value)}
            >
              {item.label} <Badge color="#3D920C" variant="light" radius="sm" style={{ marginLeft: '0.2rem' }}>{item.count}</Badge>
            </Chip>
          </div>
        ))}
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const FilterLoi = () => <CustomRefinementList attribute="category_text" label="Catégorie" />;
const FilterCode = () => <CustomRefinementList attribute="law_text" label="Lois et Réglements" searchable={true} limit={50} />;
const FilterDecisions = () => <CustomRefinementList attribute="juridiction" label="Décisions" searchable={true} limit={40} />;
const FilterKeyword = () => <CustomRefinementList attribute="keyword_decision" label="Mots clés" searchable={true} limit={40} />;
const FilterDomaine = () => <CustomRefinementList attribute="domaine_decision" label="Domaine de Droit" searchable={true} limit={40} />;
const Filtertheme = () => <CustomRefinementList attribute="theme_decision" label="Thème" searchable={true} limit={40} />;

export { FilterLoi, FilterCode, FilterDecisions, FilterKeyword, FilterDomaine, Filtertheme };
