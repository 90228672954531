import React from 'react';
import styled from 'styled-components';
import { Text, Container, ActionIcon, Group } from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import logo from '../assets/icon_app.jpg'; // Assuming this is the logo path

const FooterWrapper = styled.footer`
  margin-top: 5vh;
  padding-top: 4vh;
  padding-bottom: 4vh;
  background-color: #252a47; /* Dark background color */
  border-top: 1px solid #dee2e6;

  @media (max-height: 768px) {
    max-height: 60vh;
    overflow-y: auto;
  }

  @media (max-width: 768px) {
    margin-top: 3vh;
    padding-top: 3vh;
    padding-bottom: 3vh;
    max-height: 60vh;
    overflow-y: auto;
  }
`;

const FooterInner = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LogoWrapper = styled.div`
  max-width: 200px;
  margin-right: 4vh;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 3vh;
  }
`;

const LogoImage = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const Description = styled(Text)`
  margin-top: 1vh;
  color: #adb5bd; /* Lighter text color for description */
  font-size: 0.875rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.75rem;
    margin-top: 1vh;
    text-align: center;
  }
`;

const GroupsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const GroupWrapper = styled.div`
  width: 160px;
  margin-left: auto;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3vh;
    text-align: center;
  }
`;

const FooterLink = styled(Link)`
  display: block;
  color: #adb5bd; /* Lighter text color for links */
  font-size: 0.875rem;
  padding: 3px 0;
  transition: color 0.3s ease;

  &:hover {
    color: #ffffff; /* Change color on hover */
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
    padding: 2px 0;
  }
`;

const GroupTitle = styled(Text)`
  font-size: 1.125rem;
  font-weight: 700;
  color: #ffffff; /* White color for group titles */

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 1vh;
  }
`;

const AfterFooter = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-top: 1px solid #dee2e6;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }
`;

const SocialGroup = styled(Group)`
  gap: 0;

  @media (max-width: 768px) {
    margin-top: 1vh;
    justify-content: center;
  }
`;

const DomainText = styled(Text)`
  font-size: 0.875rem;
  color: #adb5bd;
  margin-top: 1vh;

  @media (max-width: 768px) {
    font-size: 0.75rem;
    margin-top: 1vh;
    text-align: center;
  }

  &:hover {
    color: #ffffff; /* Change color on hover */
  }
`;

export function FooterLinks() {
  const data = [
    {
      title: 'Produit',
      links: [
        { label: 'Accueil', link: '/portail' },
        { label: 'Fonctionnalités', link: '/features' },
        { label: 'Nouveautés', link: '/new' },
        { label: 'Tarifs', link: '/pricing' },
        { label: 'Envoyer une décision', link: '/submit-decision' },
      ],
    },
    {
      title: 'Plan du site',
      links: [
        { label: 'Décisions', link: '/decisions' },
        { label: 'Lois et règlements', link: '/laws' },
        { label: 'Conventions collectives', link: '/collective-agreements' },
        { label: 'Conventions fiscales', link: '/tax-agreements' },
        { label: 'Rubriques jurisprudentielles', link: '/jurisprudence' },
      ],
    },
    {
      title: 'Communauté',
      links: [
        { label: 'Avis', link: '/testimonials' },
        { label: 'Forum', link: '/blog' },
      ],
    },
    {
      title: 'Nous connaître', // Added new section
      links: [
        { label: 'A propos', link: '/about' },
        { label: 'Centre d\'aide', link: '/help-center' },
        { label: 'Nous contacter', link: '/contact' },
        { label: 'Conditions d\'utilisation', link: '/terms' },
        { label: 'Mentions Légales', link: '/legal-notice' },
      ],
    },
  ];

  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <FooterLink key={index} to={link.link}>
        {link.label}
      </FooterLink>
    ));

    return (
      <GroupWrapper key={group.title}>
        <GroupTitle>{group.title}</GroupTitle>
        {links}
      </GroupWrapper>
    );
  });

  return (
    <FooterWrapper>
      <FooterInner>
        <LogoWrapper>
          <LogoImage src={logo} alt="Logo" />
          <Description size="xs">
            La 1re solution d'intelligence juridique au Sénégal.
          </Description>
        </LogoWrapper>
        <GroupsWrapper>{groups}</GroupsWrapper>
      </FooterInner>
      <AfterFooter>
        <Text c="dimmed" size="sm" color="#adb5bd">
          © 2024 legi.sn. Tous droits réservés.
        </Text>

        <SocialGroup justify="flex-end" wrap="nowrap">
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandTwitter size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandYoutube size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandInstagram size={18} stroke={1.5} />
          </ActionIcon>
        </SocialGroup>
      </AfterFooter>
      <Container>
        <DomainText size="sm">legi.sn by AfriLex</DomainText>
      </Container>
    </FooterWrapper>
  );
}

export default FooterLinks;
