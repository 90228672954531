import React from 'react';
import { InfiniteHits, Pagination } from 'react-instantsearch';
import { Group } from '@mantine/core';
import './articleHit.css';
import { ArticleHit } from '../articleHit';

// This component now directly uses InfiniteHits, assuming it's part of the InstantSearch context
const ArticleHits = () => (
  <div>
    <InfiniteHits hitComponent={Hit}  templates= {{"showMoreText": 'Show previous'}} />
    <Group justify="center" style={{ marginTop: '20px' }}>
      <Pagination />
    </Group>
  </div>
);

// Define the Hit component to render each search result
const Hit = ({ hit }) => {
    // console.log("object : ",hit.ObjectID)
 return  (<div className='Hit' key={hit.id_collection}>
    <ArticleHit key={hit.id_collection} hit={hit} />
  </div>)
};

export default ArticleHits;
