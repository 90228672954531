import React from 'react'
import { useParams } from 'react-router-dom';
import MyNavbar from '../../component/navbar/Navbar';
import { useDisclosure } from '@mantine/hooks';
import { AppShell,Group,Burger } from '@mantine/core';
import {NavbarNested} from "../../component/verticalNavbar/NavbarNested"
import {db} from "../../config/firebase-config"
import { doc, getDoc } from "firebase/firestore";
import { useState,useEffect } from 'react';
import {CodeContentPage} from '../../component/lawsContent';
import { Loader } from '@mantine/core';



export const LawPage = (props) => {
    const { lawtype } = useParams();
    const [docData, setDocData] = useState(null);

    const [opened, { toggle }] = useDisclosure();


    const fetchData = async () => {
      try {
        const docRef = doc(db, "doclist", "doclist");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          //console.log("Document data:", lawtype,docSnap.data());
          const docTemp = docSnap.data();
         
          //console.log(docTemp[lawtype]);
          setDocData(docTemp[lawtype]);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Une erreur s'est produite :", error);
      }
    };

    useEffect(() => {

      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lawtype]);
  

     return (
            <AppShell
              header={{ height: 60 }}
              navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
              padding="md">
              <AppShell.Header>
              <Group h="100%" w="100%">
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
              <MyNavbar />
              </Group>
              </AppShell.Header>
              <AppShell.Navbar p="md" style={{"backgroundColor":"#F5F5F5"}} >
              <NavbarNested />
              </AppShell.Navbar>

              <AppShell.Main>
                <div>{docData ? <CodeContentPage docdata={docData} texttype={lawtype}/>
                :
                <Group  mt="md" mb="xs" justify="center">
                <Loader color="blue" size="xl" type="dots" />
                </Group>
                } 
                </div>
                 
              
              </AppShell.Main>
            </AppShell>
          );
}