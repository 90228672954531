import React from 'react'
import './ButtonContainer.css';

import {FilterLoi,FilterCode,FilterDecisions,FilterKeyword,FilterDomaine,Filtertheme} from '../refinementList/refinementList';

  export function HoverFilterComp (props) {
    

    return (<>
       
       <div className="button-container">
 
              <FilterLoi attribute="category_text"/>
              <FilterCode attribute="law_text"/>
              <FilterDecisions attribute="FilterDecisions"/>
              <FilterKeyword attribute="keyword_decision"/>
              <FilterDomaine attribute="domaine_decision"/>
              <Filtertheme attribute="theme_decision"/>
              </div>
          
     



          
     </>)

  } 

