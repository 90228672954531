import React from 'react'
import { Text, Group} from '@mantine/core';
import {db} from "../../config/firebase-config"
import {  doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { Loader,Flex ,HoverCard,Button} from '@mantine/core';
import { Select } from '@mantine/core';
import { Grid } from '@mantine/core';

import './TocGenerator.css'
import { TableOfContentsFloating } from './TabofContent';

const TextWithNewLines = ({ text }) => {
  const containerStyle = {
    whiteSpace: 'pre-line',
  };
  return (
    <p style={containerStyle}>
      {text}
    </p>
  );
};
const CodePenalList = (props) => {
  const {currentData: textData} = props
  const [lawText,setLawText] = useState(null)

  const renderElement = (element, index) => {
    switch (element.type) {
      case 'Preambule':
        return (
          <div  key={element.titre} id={`index-${index}`} >
            <h3>{element.titre}</h3>
            <Text ta={'left'}  size="sm" c="dimmed"><TextWithNewLines text={element.corpus} />   </Text>
            <br></br>
          </div>
        );
      case 'Livre':
        return (
          <div  key={element.titre} id={`index-${index}`} >
            <h3>{element.titre}</h3>
          </div>
        );
      case 'Titre':
        return (
          <div  key={element.titre} id={`index-${index}`} >
            <h4>{element.titre}</h4>
          </div>
        );
      case 'Chapitre':
        return (
          <div key={element.titre}  id={`index-${index}`}> 
            <h5>{element.titre}</h5>
          </div>
        );
        case 'Section':
          return (
            <div key={element.titre}  id={`index-${index}`}> 
              <h6>{element.titre}</h6>
            </div>
          );
      case 'Article':
        return (
          <div key={element.titre}>
              <Text ta={'left'}  size="sm" >{element.titre}   </Text>

              <Text ta={'left'}  size="sm" c="dimmed"><TextWithNewLines text={element.corpus} />   </Text>

              <br></br>
          </div>
        );
      default:
        return null;
    }
  };
  
  const TransformDoc = ( props ) => {
    const  textData= props

    return (
      <div key = {"law-text"}>
        {textData.map((element, index) => renderElement(element, index))}
      </div>
    );
  }; 

  const fetchData =  (props) => {
    const textData = props

    try {
      const text =  TransformDoc(textData);
      if (text) {

        setLawText(text);

      } else {
        console.log("Le document n'existe pas.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du document :", error);
    }
  };
  useEffect(() => {
      fetchData(textData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textData]);
  
  return (
    <div >
      {lawText ? [lawText] :
      <Group  mt="md" mb="xs" justify="center">
                <Loader color="blue" size="xl" type="dots" />
      </Group>
    }
    </div>
  );
};

  
  

export const CodeContentPage = (props) => {
    console.log(props)
    const {docdata,texttype} = props;


    const renderCodeItem = (codeItem) => (
                {value: codeItem.doc_id, label: codeItem.title}
    );
    const renderLinks = (codeItem,index) => {
      if (codeItem.type === "Partie") {
        console.log(codeItem)
        return { label: codeItem.titre, link: `index-${index}`, order: 0 };
      }
      if (codeItem.type === "Livre") {
        console.log(codeItem)
        return { label: codeItem.titre, link: `index-${index}`, order: 1 };
      }
      else if (codeItem.type === "Titre") {
        console.log(codeItem)
        return { label: codeItem.titre, link: `index-${index}`, order: 2};
      }
      else if (codeItem.type === "Chapitre") {
        console.log(codeItem)
        return { label: codeItem.titre, link: `index-${index}`, order: 3 };
      }
      else if (codeItem.type === "Section") {
        console.log(codeItem)
        return { label: codeItem.titre, link: `index-${index}`, order: 4 };
      }
      // Si le type n'est pas "Titre", rien n'est retourné
    };

    const [currentId,setCurrentId] = useState("");
    const [textComponent,settextComponent] = useState(null)


    const [linkok,setlinkok] = useState(null)
    const [links,setLinks] = useState(null)
    const [isMobile,setIsmobile] = useState(window.innerWidth <= 1000);
    const handleTextItOnChange = (props) => {
      
      console.log(props)
      setCurrentId(props);
    };



    const fetchData = async () => {
      try {
        console.log(texttype)
        setlinkok(null)
        const docRef = doc(db, texttype, currentId);
        const mobile = window.innerWidth <= 1000
        setIsmobile(mobile)
        const text = await getDoc(docRef);
        if (text.exists()) {
         
          const textBrut = text.data()
          const textB = textBrut["text"]
          console.log(textB)
  
          settextComponent(<CodePenalList currentData={textB} />)
          const templink = textB.map(renderLinks).filter(link => link !== undefined);
          setLinks(templink)
          setlinkok(true)
          console.log(links)
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du document :", error);
      }
    };
  
    useEffect(() => {
     
        fetchData();
        
      
   
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentId]);

    return (
  
          <div>
                <Select
                    label="Choisir un texte"
                    placeholder="Choisissez un texte"
                    value={currentId}
                    onChange={(_value) => handleTextItOnChange(_value) }
                    data={docdata.map(renderCodeItem)}
                  />
              <hr/>
              {!isMobile?   <Grid>
      <Grid.Col span={4}> <div id="toc" className='toc'> {linkok ? <TableOfContentsFloating links={links} /> : <div>Table des matières</div> } </div> </Grid.Col>

        <Grid.Col span={8}> <div id="content" className='content' >{linkok ?   [textComponent] :<Text > {currentId} </Text>} </div></Grid.Col>
      </Grid>  : 
         <Flex
         direction={{ base: 'column', sm: 'row' }}
         gap={{ base: 'sm', sm: 'lg' }}
         justify={{ sm: 'center' }}
       >
            <HoverCard  shadow="md" withArrow position="bottom" arrowSize={10} withinPortal offset={20} >
            <HoverCard.Target>
            <Button variant="light" size="xs"  >Table des matières</Button>
            </HoverCard.Target>
            <HoverCard.Dropdown style={{"maxHeight":"20rem","overflowY":"scroll","maxWidth":"80%"}}>
            <div  id="toc"> {links ?   <TableOfContentsFloating links={links} /> : <div></div> } </div>
            </HoverCard.Dropdown>
        </HoverCard>
        

         
         <div id="content" className='content' >{textComponent ?   [textComponent] :<Text > {currentId} </Text>} </div>

          </Flex>
      }


          
          

        </div>
      
  
    );
  }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
